import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import HeroHeader from "../components/hero-header";
import WebFooter from "../components/web-footer";
import SoftwareCard from "../components/SoftwareCard";

const Home = () => {
  let heroHeader = useRef();

  heroHeader.current = HeroHeader(
    "Struts Technology",
    "We offer custom made software tailored to your specification."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {heroHeader.current}
      <section className="section">
        <div className="container">
          {/* <h1 className="title">Struts Technology</h1>
          <p className="subtitle">
            Electronic Signature Device signing, reporting and analytics tool.
          </p> */}

          <section className="section is-normal">
            <h1 className="title">Custom Made Software</h1>
            <h2 className="subtitle">
              We provide bespoke software solutions designed to meet your unique
              requirements.
            </h2>

            <br />

            <div class="columns" style={{ marginTop: "5px" }}>
              <div class="column">
                <a
                  href="https://agriprofit.strutstechnology.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SoftwareCard
                    name="Custom Made Software"
                    handle="TechStruts"
                    hashTags="#TechStruts"
                    description="We offer custom made software tailored to your specification."
                    imgSrc="/assets/agriprofit.png"
                  />
                </a>
              </div>
              <div class="column">
                <Link className="" to="/fiscal-software">
                  <SoftwareCard
                    name="Fiscal Software"
                    handle="TechStruts"
                    hashTags="#TechStruts"
                    description="We automate invoice and receipt documents processing for tax compliance."
                    imgSrc="/assets/kodi-africa-dashboard.png"
                  />
                </Link>
              </div>
              <div class="column">
                <Link className="" to="/struts-ai">
                  <SoftwareCard
                    name="Struts AI"
                    handle="StrutsAI"
                    hashTags="#TechStruts #StrutsAI"
                    description="Unleash the Power of AI to Revolutionize Your Business."
                    imgSrc="/assets/struts-ai-home.png"
                  />
                </Link>
              </div>
            </div>

            <div class="columns" style={{ marginTop: "5px" }}>
              <div class="column">
                {/* <a
                  href="https://agriprofit.strutstechnology.com/"
                  target="_blank"
                  rel="noreferrer"
                > */}
                <Link className="" to="/xero-esd">
                  <SoftwareCard
                    name="Xero Custom ESD Software"
                    handle="TechStruts"
                    hashTags="#TechStruts"
                    description="Connect to Xero and fiscalize your invoices automatically."
                    imgSrc="/assets/xero-web.png"
                  />
                </Link>
              </div>

              <div class="column">
                <Link className="" to="/contact">
                  <SoftwareCard
                    name="Navision Custom ESD Software"
                    handle="TechStruts"
                    hashTags="#TechStruts"
                    description="Process your Navision PDF invoices fiscalzing them in compliance with revenue authorities."
                    imgSrc="/assets/nav-web.png"
                  />
                </Link>
              </div>

              <div class="column">
                <Link className="" to="/contact">
                  <SoftwareCard
                    name="PDF Invoices to Excel"
                    handle="TechStruts"
                    hashTags="#TechStruts"
                    description="Convert PDF invoices to searchable reports which can be exported to excel."
                    imgSrc="/assets/pdf-to-excel-processing.png"
                  />
                </Link>
              </div>
            </div>
          </section>
        </div>
      </section>
      <WebFooter />
    </>
  );
};

export default Home;
