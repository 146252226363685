import React, { useEffect, useRef } from "react";
import HeroHeader from "../components/hero-header";
import WebFooter from "../components/web-footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMailBulk,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  let heroHeader = useRef();

  heroHeader.current = HeroHeader("Struts Technology", "Custom Made Software");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {heroHeader.current}
      <section className="section">
        <div className="container">
          <section className="section is-normal">
            <h1 className="title">Contact Us</h1>
            <h2 className="subtitle">
              You can reach us at info@strutstechnology.com or by phone +254 703
              397527. <br />
              <br />
              Let's engage in a conversation and transform your ideas into
              reality.
            </h2>
            <br />
            <FontAwesomeIcon icon={faPhone} /> &nbsp;
            <span class="icon-text">
              <span style={{}}>+254 703 397527</span>
            </span>
            <br />
            <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
            <span class="icon-text">
              <span style={{}}>info@strutstechnology.com</span>
            </span>
            <br />
            <FontAwesomeIcon icon={faMailBulk} /> &nbsp;
            <span class="icon-text">
              <span style={{}}>P.O.Box 272-00208 Ngong Hills, Kenya</span>
            </span>
            <br />
            <FontAwesomeIcon icon={faTwitter} /> &nbsp;
            <a
              href="https://twitter.com/StrutsTech"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00d1b2" }}
            >
              @StrutsTech
            </a>{" "}
            &nbsp;
            <a
              href="https://twitter.com/StrutsAI"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00d1b2" }}
            >
              @StrutsAI
            </a>
            &nbsp;
            <a
              href="https://twitter.com/Kodi_Africa"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00d1b2" }}
            >
              @Kodi_Africa
            </a>
            <br />
            <br />
            <br />
            <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                Struts Technology
              </a>
            </p>
          </section>
        </div>
      </section>
      <WebFooter />
    </>
  );
};

export default Contact;
