import React from "react";
import { Link } from "react-router-dom";

const DocumentationSideMenu = () => {
  return (
    <>
      <aside className="menu" style={{ padding: "10px" }}>
        <p className="menu-label">Getting Started</p>
        <ul className="menu-list">
          <li>
            <Link to="/documentation">Getting Started</Link>
          </li>
        </ul>
        <p className="menu-label">Invoices</p>
        <ul className="menu-list">
          <li>
            <Link to="/invoice-documentation">Invoices</Link>
          </li>
          <li>
            <Link to="/zreports-documentation">Z Reports</Link>
          </li>
        </ul>
        <p className="menu-label">Analytics</p>
        <ul className="menu-list">
          <li>
            <Link to="/signing-analytics-documentation">Signing Analytics</Link>
          </li>
          <li>
            <Link to="/system-logs-documentation">System Logs</Link>
          </li>
        </ul>
        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          <li>
            <Link to="/vfd-configuration-documentation">VFD Configuration</Link>
          </li>
          <li>
            <Link to="/profile-documentation">Profile</Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default DocumentationSideMenu;
