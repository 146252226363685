import React from "react";
import TRAInvoiceTotalsView from "./tra_invoice_totals_view";

const TRAInvoiceFooter = (invoice) => {
  let traInvoiceTotals = TRAInvoiceTotalsView(invoice);

  return (
    <>
      <div className="row">
        <div
          className="col-xs-12"
          style={{
            backgroundImage: "url('../images/specimen.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
          }}
        >
          {traInvoiceTotals}

          <br />
          <center>
            Verification URL:{" "}
            <a
              href={invoice.verification_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {invoice.verification_url}
            </a>
          </center>
        </div>
        {/* <!-- /.col --> */}
      </div>
    </>
  );
};

export default TRAInvoiceFooter;
