import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const WebNavabar = () => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");

    if (accessToken) {
      setIsLoggedIn(true);
    }

    window.scrollTo(0, 0);
  }, []);

  const logoutUser = (event) => {
    localStorage.removeItem("esd_access_token");
    localStorage.removeItem("esd_user_id");
    navigate("/");
  };

  let loginDashboardbutton = isLoggedIn ? (
    <button className="button is-primary" onClick={logoutUser}>
      <strong>Log Out</strong>
    </button>
  ) : (
    <Link to="/login" className="button is-primary">
      <strong>Log in</strong>
    </Link>
  );

  let homeDashboardLink = isLoggedIn ? (
    <Link to="/dashboard" className="navbar-item">
      Dashboard
    </Link>
  ) : (
    <Link to="/" className="navbar-item">
      Home
    </Link>
  );

  return (
    <nav
      className="navbar is-light"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img
              src="/assets/logo.png"
              alt="Struts Technology"
              style={{ maxHeight: "2.50rem" }}
            />
            &nbsp; <b>Struts Technology</b>
          </Link>

          <Link
            to="/"
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </Link>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            {homeDashboardLink}

            <Link to="/documentation" className="navbar-item">
              Documentation
            </Link>

            <Link to="/struts-ai" className="navbar-item">
              Struts AI
            </Link>

            <div className="navbar-item has-dropdown is-hoverable">
              <Link to="/" className="navbar-link">
                More
              </Link>

              <div className="navbar-dropdown">
                <Link to="/about" className="navbar-item">
                  About
                </Link>
                <Link to="/contact" className="navbar-item">
                  Contact
                </Link>
              </div>
            </div>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">{loginDashboardbutton}</div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default WebNavabar;
