import React from "react";

const TRACompanyView = (company) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-12 invoice-header">
          <center>
            <h4>
              <b>{company.name}</b>
            </h4>
          </center>
        </div>
      </div>
      <div className="row invoice-info col-xs-12">
        <center>
          <div className="col-xs-12 invoice-col">
            <b>{""}</b>
            <br />
            <b>MOBILE:</b> {company.mobile}
            <br />
            <b>TIN:</b> {company.tin}
            <br />
            <b>VRN:</b> {company.vrn}
            <br />
            <b>SERIAL NO:</b> {company.serial}
            <br />
            <b>UIN: </b> {company.uin}
            <br />
            <b>TAX OFFICE: </b> {company.tax_office}
          </div>
        </center>
      </div>
    </>
  );
};

export default TRACompanyView;
