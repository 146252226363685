import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const Documentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Getting Started</h2>
              <p className="">
                To be able to sign invoices via VFD (Virtual Fiscal Device), you
                will need to have obtained the relevant certificate(s) and
                credentials from TRA. <br />
                After that, your system will get{" "}
                <Link
                  to="/vfd-configuration-documentation"
                  style={{ color: "#00d1b2" }}
                >
                  setup
                </Link>{" "}
                with those configurations, after which you can be able to
                proceed to sign invoices.{" "}
              </p>
              <br />

              <p className="">
                Once your system has been configured, you can proceed to{" "}
                <Link to="/login" style={{ color: "#00d1b2" }}>
                  login
                </Link>{" "}
                with the provided credentials. After a successful login, you
                will be able to see a{" "}
                <Link to="/dashboard" style={{ color: "#00d1b2" }}>
                  dashboard
                </Link>{" "}
                similar to below. Initially, the numbers will read 0 before
                documents have been proceesed, and the values will increment as
                you process documents.
              </p>
              <br />
              <br />
              <img src="/assets/dashboard.png" alt="Dashboard" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Documentation;
