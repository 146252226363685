import React from "react";

function SignUp() {
  return (
    <div>
      <div className="container">
        <div className="columns is-justify-content-center">
          <div className="column is-6-tablet is-5-desktop is-4-widescreen is-3-fullh">
            <form
              method="POST"
              action="https://herotofu.com/start"
              className="box p-5"
            >
              <label className="is-block mb-4">
                <span className="is-block mb-2">Email address</span>
                <input
                  required
                  type="email"
                  className="input"
                  placeholder="joe.bloggs@example.com"
                />
              </label>

              <label className="is-block mb-4">
                <span className="is-block mb-2">Password</span>
                <input
                  name="password"
                  type="password"
                  className="input"
                  minlength="6"
                  placeholder="(must be 6+ chars)"
                  required
                />
              </label>

              <div className="mb-4">
                <label>
                  <input type="checkbox" name="tos" value="yes" required />
                  <span>I agree with the TOS</span>
                </label>
              </div>

              <div className="mb-4">
                <button type="submit" className="button is-link px-4">
                  Sign Up
                </button>
              </div>

              <div>
                <div className="is-size-7 has-text-right">
                  by
                  <a
                    href="https://herotofu.com/start"
                    className="has-text-dark"
                  >
                    HeroTofu
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
