import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const VFDConfigurationDocumentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">VFD Configuration</h2>

              <p className="">
                In the{" "}
                <Link to="/vfd-configuration" style={{ color: "#00d1b2" }}>
                  VFD Configuration
                </Link>{" "}
                section, you can be able to setup your company details for
                automated TRA invoice Signing. <br />
                The configuration details will be requested from TRA, and input
                in this section in order to get started with signing.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VFDConfigurationDocumentation;
