import React from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "../../configs/chatbotConfig";
import AIMessageParser from "../../chatbot/AIMessageParser";
import AIActionProvider from "../../chatbot/AIActionProvider.js";

const ChatBotForm = () => {
  return (
    <Chatbot
      style={{ width: "max-content" }}
      config={config}
      messageParser={AIMessageParser}
      actionProvider={AIActionProvider}
    />
  );
};

export default ChatBotForm;
