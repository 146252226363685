import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/admin_navbar";
import AdminSideMenu from "../../components/admin_side_menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Profile = () => {
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState("");
  const [tinNumber, setTinNumber] = useState("");
  const [user, setUser] = useState({});
  const [createdAt, setCreatedAt] = useState("");

  const getUser = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("esd_access_token");
    var userID = localStorage.getItem("esd_user_id");

    try {
      const res = await axios({
        url: apiBaseURL + "/users/" + userID,
        headers: {
          "x-esdws-token": accessToken,
          "x-esdws-application": "user",
        },
        method: "GET",
      });

      let userData = JSON.parse(JSON.stringify(res.data));
      setUser(userData);
      setCreatedAt(userData.created_at.substring(0, 10));

      if (userData.company.tin) {
        setTinNumber(userData.company.tin);
      }

      if (userData.company.vfd_configuration) {
        if (userData.company.vfd_configuration.id) {
          setBusinessName(userData.company.vfd_configuration.business_name);
        }
      }
    } catch (err) {
      console.error("getUser err = " + err.message);
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    getUser();

    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Profile</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>

            {/* User Profile */}
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "8px", marginTop: "-10px" }}
            >
              <div className="rows">
                <div className="row is-full">
                  <b>{user.first_name}'s Profile</b>
                  <br />
                  First Name: {user.first_name}
                  <br />
                  Last Name: {user.last_name}
                  <br />
                  Email: {user.email}
                  <br />
                  Created At: {createdAt}
                  <br />
                </div>

                <br />
                <br />

                <div className="row is-full">
                  {/* Business Profile */}
                  <b>{businessName}</b>
                  <br />
                  TIN Number: {tinNumber}
                  <br />
                </div>
              </div>
            </div>

            <div className="column is-three-quarters"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
