import React from "react";

const ZReportView = (zreport) => {
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  var pdfFile = apiBaseURL + "/zreports/" + zreport.id + "/pdf";

  return (
    <>
      <p>
        <b>ZReport #{zreport.id}</b>
      </p>
      <br />
      File name: {zreport.file_name}
      <br />
      Net Amount: {zreport.net_amount}
      <br />
      Vat: {zreport.vat_amount}
      <br />
      Total Amount: {zreport.total_amount}
      <br />
      ZReport Date: {zreport.z_report_date}
      <br />
      <br />
      {/* Enable where pdf files are involved, not needed for API signing. */}
      <iframe src={pdfFile} title="testPdf" height="900px" width="100%" />
      <br />
      <br />
    </>
  );
};

export default ZReportView;
