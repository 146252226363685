import React from "react";

const TRAInvoiceDetailsView = (invoice) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-12 invoice-header">
          <hr style={{ borderTop: "dotted 1px;" }} />
          <b>INVOICE NO: </b> {invoice.invoice_number}
          <br />
          <b>Z NUMBER: </b> 4/20230816
          <br />
          <b>RECEIPT DATE: </b> {invoice.receipt_date}
          <br />
          <b>RECEIPT TIME: </b> {invoice.receipt_time}
          <br />
          <hr style={{ borderTop: "dotted 1px;" }} />
        </div>
      </div>
    </>
  );
};

export default TRAInvoiceDetailsView;
