import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const InvoiceDocumentation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Invoices</h2>

              <p className="">
                Invoices can get signed by either of two ways;
                <br />
                <br />
                <div className="ml-6">
                  <ol type="1">
                    <li>API</li>
                    <li>Invoice documents processing</li>
                  </ol>
                </div>
              </p>
              <br />

              <p className="">
                The provided Struts Technology Custom ESD System comes with API
                Endpoints which you can call so sign invoices, as well as view
                details and reports on signed invoices. A custom solution can
                also be provided if you want to use soft copy documents, we can
                process the documents and post them to TRA automatically via
                VFD. We also have automated email sending if you wish for the
                signed invoice documents to be sent automatically to clients.
              </p>
              <br />

              <p className="">
                As invoices get processed, you will see a{" "}
                <Link to="/invoices" style={{ color: "#00d1b2" }}>
                  report
                </Link>{" "}
                similar to below;
                <br />
                <br />
                <img src="/assets/invoices.png" alt="Invoice Reports" />
                <br />
                <br />
                Sample Invoice view;
                <br />
                <br />
                <img src="/assets/invoice-view-1.png" alt="Invoice Reports" />
                <br />
                <br />
                Sample Invoice view with QR Code;
                <br />
                <br />
                <img src="/assets/invoice-view-2.png" alt="Invoice Reports" />
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvoiceDocumentation;
