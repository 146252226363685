import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const HeroHeader = (title, subtitle) => {
  // Get the current location object
  const location = useLocation();
  // Access the pathname (URL path) property from the location object
  const currentPath = location.pathname;

  const isHomeMenu = currentPath === "/" || currentPath === "/contact2";

  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");

    if (accessToken) {
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <section
          className="hero is-primary is-medium header-image"
          style={{ backgroundImage: 'url("./assets/bg-3.png")' }}
        >
          {/* <!-- Hero header: will stick at the top --> */}
          <div className="hero-head">
            <div className="container">
              <header className="navbar">
                <div className="navbar-brand">
                  <Link className="navbar-item" to="/">
                    <img
                      src="/assets/logo.png"
                      alt="Struts Technology: Custom Made Software"
                      style={{ maxHeight: "2.50rem" }}
                    />
                    &nbsp; <b style={{ color: "black" }}>Struts Technology</b>
                  </Link>

                  <div
                    className={`navbar-burger ${showNav ? "is-active" : ""}`}
                    onClick={() => setShowNav(!showNav)}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className={`navbar-burger ${showNav ? "is-active" : ""}`}>
                  {/* <!-- navbar start, navbar end --> */}
                  {/* <div className="navbar-end">
                    <Link to="/documentation" className="navbar-item">
                      Documentation
                    </Link>

                    <Link to="/struts-ai" className="navbar-item">
                      Struts AI
                    </Link>

                    <Link to="/about" className="navbar-item">
                      About
                    </Link>

                    <Link to="/contact" className="navbar-item">
                      Contact
                    </Link>
                  </div> */}
                </div>

                <div id="navbarBasicExample" className="navbar-menu">
                  <div className="navbar-start">
                    {/* {homeDashboardLink} */}

                    {/* <Link to="/documentation" className="navbar-item">
                      Documentation
                    </Link>

                    <Link to="/struts-ai" className="navbar-item">
                      Struts AI
                    </Link>

                    <Link to="/about" className="navbar-item">
                      About
                    </Link>

                    <Link to="/contact" className="navbar-item">
                      Contact
                    </Link> */}

                    {/* <div className="navbar-item has-dropdown is-hoverable">
                    <Link to="/" className="navbar-link">
                      More
                    </Link>

                    <div className="navbar-dropdown">
                      <Link to="/about" className="navbar-item">
                        About
                      </Link>
                      <Link to="/contact" className="navbar-item">
                        Contact
                      </Link>                     
                    </div>
                  </div> */}
                  </div>
                  <div className="navbar-end">
                    <div className="navbar-item">
                      {/* <div className="buttons">{loginDashboardbutton}</div> */}
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </div>

          {/* <!-- Hero content: will be in the middle --> */}
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title">{title}</h1>
              <h2 className="subtitle">{subtitle}</h2>
            </div>
          </div>
          {/* <!-- Hero footer: will stick at the bottom --> */}
          <div className="hero-foot">
            <nav className="tabs is-boxed is-fullwidth">
              <div className="container">
                <ul>
                  <li className={`${isHomeMenu ? "is-active" : ""}`}>
                    <Link to="/" className="navbar-item">
                      Home
                    </Link>
                  </li>
                  <li
                    className={`${
                      currentPath === "/fiscal-software" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/fiscal-software">Fiscal Software</Link>
                  </li>
                  <li
                    className={`${
                      currentPath === "/struts-ai" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/struts-ai">Struts AI</Link>
                  </li>
                  {/* <li
                    className={`${
                      currentPath === "/autofisc" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/autofisc">Autofisc </Link>
                  </li> */}
                  {/* <li
                    className={`${
                      currentPath === "/kodi-africa" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/kodi-africa">Kodi Africa </Link>
                  </li> */}
                  {/* <li
                    className={`${
                      currentPath === "/struts-pos" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/struts-pos">Struts POS</Link>
                  </li> */}
                  {/* <li
                    className={`${
                      currentPath === "/xero-esd" ? "is-active" : ""
                    }`}
                  >
                    <Link to="/xero-esd">Xero ESD </Link>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </section>
      </div>
    </>
  );
};

export default HeroHeader;
