import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import CompanyView from "../shared/company_view";
import TRAInvoicePreview from "../shared/tra_invoice_view_with_params";
import ZReportView from "../shared/zreport_view";
import axios from "axios";

const Log = () => {
  var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  let { id } = useParams();

  const navigate = useNavigate();

  const [logType, setLogType] = useState("");
  const [log, setLog] = useState({});

  var companyView = useRef();
  var traInvoiceView = useRef();
  var zReportView = useRef();

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    window.scrollTo(0, 0);

    var url = apiBaseURL + "/logs/" + id;

    const config = {
      headers: {
        "x-esdws-token": accessToken,
        "x-esdws-application": "user",
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let logData = JSON.parse(JSON.stringify(response.data));
        setLog(logData);

        setLogType(logData.log_type);

        if (logData.company) {
          companyView.current = CompanyView(logData.company);
        }

        if (logData.invoice) {
          traInvoiceView.current = TRAInvoicePreview(
            logData.company,
            logData.invoice,
            logData.invoice.items
          );
        }

        if (logData.z_report) {
          zReportView.current = ZReportView(logData.z_report);
        }
      })
      .catch((err) => console.error("fetchLogs err = " + err.message));
  }, [apiBaseURL, id, navigate, log.verification_url, traInvoiceView]);

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">Profile</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "8px", marginTop: "-70px" }}
            >
              <p>
                <b>
                  #{log.id} {log.log_type}
                </b>
              </p>
              <br />
              <p
                style={{
                  backgroundColor: log.is_error ? "#f96854" : "#00ebc8",
                }}
              >
                {" "}
                Content: {log.content}
              </p>
              Type: {log.log_type}
              <br />
              Resource ID: {log.resource_id}
              <br />
              Is Error: {String(log.is_error)}
              <br />
              Created At: {log.created_at}
              <br />
              {/* Start of TRA Custom page */}
              {log.invoice && traInvoiceView.current}
              {/* End of TRA Custom page */}
              <br />
              {/* Z-Report View  */}
              {log.z_report && zReportView.current}
              {logType === "startup" && companyView.current}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Log;
