import React from "react";

const SoftwareCard = ({ name, handle, hashTags, description, imgSrc }) => {
  let twitterURL = "https://twitter.com/" + handle;
  return (
    <>
      <div class="card">
        <div class="card-image">
          <figure class="image is-4by3">
            <img src={imgSrc} alt={name} />
          </figure>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image is-48x48">
                <img src="/assets/logo.png" alt="Struts Technology" />
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4">{name}</p>
              <p class="subtitle is-6">@{handle}</p>
            </div>
          </div>

          <div class="content">
            {description}
            <a
              href={twitterURL}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00d1b2" }}
            >
              &nbsp;&nbsp;{hashTags}
            </a>
            <br />
            {/* <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftwareCard;
