import React, { useEffect, useRef } from "react";
import HeroHeader from "../components/hero-header";
import WebFooter from "../components/web-footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { Link } from "react-router-dom";

const XeroESD = () => {
  let heroHeader = useRef();

  heroHeader.current = HeroHeader(
    "Kodi Africa",
    "Automated Fiscal Documents Processing for Tax Compliance."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {heroHeader.current}
      <section className="section">
        <div className="container">
          {/* <h1 className="title">Struts Technology</h1>
          <p className="subtitle">
            Electronic Signature Device signing, reporting and analytics tool.
          </p> */}

          <section className="section is-normal">
            <h1 className="title">Xero ESD</h1>
            <h2 className="subtitle">
              Connecto to Xero with our custom made ESD Software and fiscalize
              your invoices automatically.
            </h2>
            <br />
            <a
              href="https://kodi.africa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/xero-web.png" alt="Kodi.Africa Dashboard" />
            </a>
            <br />
            <br />
            <h2 className="subtitle">
              Download the application below if you are using Xero. You can
              download the documentation as well, so that you are able to set it
              up.
            </h2>
            <div
              className="buttons"
              style={{ marginTop: "2px", marginBottom: "25px" }}
            >
              <a
                href="https://mega.nz/file/BiRTTQTR#_2yl-dWqv-X6PxWjgroOFrpfkP_PLlwUlZtFkl2MOLo"
                target="_blank"
                rel="noopener noreferrer"
                className="button is-primary"
              >
                <strong>Download Application</strong>
              </a>
              &nbsp;
              <a
                href="/assets/Xero Custom ESD System Documentation v1.0.0.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="button is-primary"
                download
              >
                <strong>Download Documentation</strong>
              </a>
              &nbsp;
              {/* <Link to="/download-application" className="button is-primary">
                <strong>Download Application</strong>
              </Link> */}
            </div>
            <FontAwesomeIcon icon={faTwitter} /> &nbsp;
            <a
              href="https://twitter.com/Kodi_Africa"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00d1b2" }}
            >
              @Kodi_Africa
            </a>
            <br />
            {/* <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                Struts Technology
              </a>
            </p> */}
          </section>
        </div>
      </section>
      <WebFooter />
    </>
  );
};

export default XeroESD;
