import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdminNavbar from "../../../components/admin_navbar";
import AdminSideMenu from "../../../components/admin_side_menu";
import axios from "axios";

const VFDConfiguration = () => {
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState("");
  const [certKey, setCertKey] = useState("");
  const [certPassword, setCertPassword] = useState("");
  const [certSerial, setCertSerial] = useState("");
  const [tinNumber, setTinNumber] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("Choose certificate");
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");
  const [vfdConfiguration, setVFDConfiguration] = useState({});

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");
    if (!accessToken) {
      navigate("/");
    }

    getUser();

    window.scrollTo(0, 0);
  }, [navigate]);

  const getUser = async () => {
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("esd_access_token");
    var userID = localStorage.getItem("esd_user_id");

    try {
      const res = await axios({
        url: apiBaseURL + "/users/" + userID,
        headers: {
          "x-esdws-token": accessToken,
          "x-esdws-application": "user",
        },
        method: "GET",
      });

      let userData = JSON.parse(JSON.stringify(res.data));

      if (userData) {
        if (userData.company.tin) {
          setTinNumber(userData.company.tin);
        }

        if (userData.company.vfd_configuration) {
          setVFDConfiguration(userData.company.vfd_configuration);

          if (userData.company.vfd_configuration.id) {
            setBusinessName(userData.company.vfd_configuration.business_name);
            setCertKey(userData.company.vfd_configuration.cert_key);
            setCertPassword(userData.company.vfd_configuration.cert_password);
            setCertSerial(userData.company.vfd_configuration.cert_serial);
            setFileName(userData.company.vfd_configuration.cert_filename);
          }
        }
      }
    } catch (err) {
      console.error("getUser err = " + err.message);
    }
  };

  function uploadCertificate(event) {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  }

  function save(event) {
    event.preventDefault();

    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    var accessToken = localStorage.getItem("esd_access_token");
    var url = apiBaseURL + "/vfd_configurations";

    if (vfdConfiguration.id && !file) {
      axios({
        url: apiBaseURL + "/vfd_configurations/" + vfdConfiguration.id,
        method: "PUT",
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          "x-esdws-token": accessToken,
          "x-esdws-application": "user",
          "x-client-identifier": "web",
        },
        data: {
          business_name: businessName,
          cert_key: certKey,
          cert_password: certPassword,
          cert_serial: certSerial,
        },
      })
        .then((response) => {
          if (response.data.id) {
            var successMsg =
              "VFD Configuration updated successfully. Please restart the system for the changes to take effect.";
            setSuccessAlert(successMsg);
            setErrorAlert("");
          } else {
            setErrorAlert("Error updating VFD Configuration: " + response.data);
            setSuccessAlert("");
          }
        })
        .catch((error) => {
          setErrorAlert("Error updating VFD Configuration");
          setSuccessAlert("");
        });
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("business_name", businessName);
      formData.append("tin_number", tinNumber);
      formData.append("cert_key", certKey);
      formData.append("cert_password", certPassword);
      formData.append("cert_serial", certSerial);
      formData.append("cert_filename", fileName);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-esdws-token": accessToken,
          "x-esdws-application": "user",
        },
      };
      axios.post(url, formData, config).then((response) => {
        if (response.data.id) {
          var successMsg =
            "VFD Configuration saved successfully. Please restart the system for the changes to take effect.";
          setSuccessAlert(successMsg);
          setErrorAlert("");
        } else {
          setErrorAlert("Error saving VFD Configuration: " + response.data);
          setSuccessAlert("");
        }
      });
    }
  }

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      <AdminNavbar />

      <section className="section">
        <div className="container">
          <h1 className="title">VFD Configuration</h1>

          <div className="columns">
            <div className="column is-one-quarter">
              <AdminSideMenu />
            </div>
            <div className="column is-three-quarters">
              <div style={{ marginBottom: "8px", marginTop: "-70px" }}>
                <b>VFD Configuration</b>
                &nbsp;&nbsp;
                <Link
                  to="/vfd-configuration-documentation"
                  style={{
                    color: "#00d1b2",
                    fontWeight: "bold",
                    fontSize: "9pt",
                  }}
                >
                  Info
                </Link>
              </div>
              <br />
              {alert}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Business Name</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Business Name"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="file" style={{ marginBottom: "8px" }}>
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        name="cert"
                        onChange={uploadCertificate}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">{fileName}</span>
                      </span>
                    </label>
                  </div>
                  {/* <br /> */}
                  <div className="field">
                    <label className="label">TIN Number</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="TIN Number"
                        value={tinNumber}
                        readOnly
                        onChange={(e) => setTinNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Cert Key</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Cert Key"
                        value={certKey}
                        onChange={(e) => setCertKey(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Cert Password</label>
                    <div className="control">
                      <input
                        className="input"
                        type="password"
                        placeholder="Cert Password"
                        value={certPassword}
                        onChange={(e) => setCertPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Cert Serial</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Cert Serial"
                        value={certSerial}
                        onChange={(e) => setCertSerial(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <button className="button is-link" onClick={save}>
                      Save
                    </button>
                  </div>
                </div>
                <div className="column">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VFDConfiguration;
