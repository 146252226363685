import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import HeroHeader from "../components/hero-header";
import WebFooter from "../components/web-footer";

const Autofisc = () => {
  let heroHeader = useRef();

  heroHeader.current = HeroHeader(
    "Autofisc",
    "Automated Fiscal Documents Processing"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {heroHeader.current}
      <section className="section">
        <div className="container">
          {/* <h1 className="title">Struts Technology</h1>
          <p className="subtitle">
            Electronic Signature Device signing, reporting and analytics tool.
          </p> */}

          <section className="section is-normal">
            <h1 className="title">Automated Fiscal Documents Processing</h1>
            <h2 className="subtitle">
              We automate invoice and receipt documents processing and post them
              to revenue authority servers.
            </h2>

            <div>
              <div className="buttons">
                <Link
                  to="/contact"
                  className="button is-primary"
                  href="/contact"
                >
                  <strong>Contact Us</strong>
                </Link>
              </div>
            </div>
            <br />
            <br />
            <br />

            <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00d1b2" }}
              >
                Struts Technology
              </a>
            </p>
          </section>
        </div>
      </section>
      <WebFooter />
    </>
  );
};

export default Autofisc;
