import React, { useEffect, useRef, useState } from "react";
import HeroHeader from "../components/hero-header";
import WebFooter from "../components/web-footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const DownloadApplicationForm = () => {
  let heroHeader = useRef();

  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [applicationType, setApplicationType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState("");

  heroHeader.current = HeroHeader(
    "Kodi Africa",
    "Automated Fiscal Documents Processing for Tax Compliance."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loginUser = (event) => {
    // Prevent page reload
    event.preventDefault();
    var apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    setIsLoading(true);
    axios({
      url: `${apiBaseURL}/sessions`,
      method: "POST",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-esdws-application": "user",
        "x-client-identifier": "web",
      },
      data: {
        companyName: companyName,
        contactName: contactName,
        email: emailAddress,
        applicationType: applicationType,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.user.id) {
          localStorage.setItem("esd_user_id", response.data.user.id);
          localStorage.setItem(
            "esd_access_token",
            response.headers["x-esdws-token"]
          );
          localStorage.setItem("esd_user", response.data.user);

          var welcomeMessage = "Login successful. Redirecting to dashboard...";
          setSuccessAlert(welcomeMessage);
          setErrorAlert("");
          // redirect to dashboard after a successful login
          navigate("/dashboard");
        } else {
          setErrorAlert("Login failed.");
          setSuccessAlert("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorAlert("Login failed.");
        setSuccessAlert("");
      });
  };

  let alert;
  if (errorAlert.length > 0) {
    alert = (
      <div className="notification is-danger">&nbsp;&nbsp;{errorAlert} </div>
    );
  } else if (successAlert.length > 0) {
    alert = (
      <div className="notification is-success">&nbsp;&nbsp;{successAlert} </div>
    );
  }

  return (
    <>
      {heroHeader.current}
      <section className="section">
        <div className="container is-justify-content-center">
          <div
            className="columns"
            style={{
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <div className="column"></div>
            <div className="column is-6 card" style={{}}>
              <form onSubmit={loginUser}>
                <div
                  className="container"
                  style={{
                    borderRadius: "3px;padding: 15px",
                  }}
                >
                  <div
                    className="field"
                    style={{ marginBottom: "20px", marginTop: "30px" }}
                  >
                    <h2 className="title">
                      Custom Software Application Request Form
                    </h2>{" "}
                    <br />
                    <label className="label">Company Name</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Company Name"
                        name="companyName"
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Contact Name</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Contact Name"
                        name="contactName"
                        onChange={(e) => setContactName(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="field" style={{ marginBottom: "20px" }}>
                    <label className="label">Email Address</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Email Address"
                        name="companyName"
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="field" style={{ marginBottom: "20px" }}>
                    <label className="label">Application type request</label>
                    <div className="control">
                      <div class="select">
                        <select
                          onChange={(e) => setApplicationType(e.target.value)}
                        >
                          <option>Select Application</option>
                          <option value="xero-esd">Xero ESD</option>
                          <option value="navision-esd">Navision ESD</option>
                          <option value="pdf-to-excel">
                            PDF to Excel Reports
                          </option>
                          <option value="custom-application">
                            Custom Made Application
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field" style={{ marginBottom: "20px" }}>
                    <label className="label">
                      Upload some sample PDF invoice files (about 3)
                    </label>
                    <div className="control">
                      <div class="file">
                        <label class="file-label">
                          <input class="file-input" type="file" name="resume" />
                          <span class="file-cta">
                            <span class="file-icon">
                              <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-label">Sample invoice files</span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {!isLoading && (
                    <div className="field">
                      <button className="button is-link" onClick={loginUser}>
                        Request Application
                      </button>
                    </div>
                  )}
                </div>
              </form>

              <br />
              {alert}
            </div>
            <div className="column"></div>
          </div>
        </div>
      </section>
      <WebFooter />
    </>
  );
};

export default DownloadApplicationForm;
