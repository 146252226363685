import React from "react";

const TRAInvoiceItemsView = (invoiceItems) => {
  return (
    <>
      {/* <!-- Table row --> */}
      <h3>Purchased Items</h3>
      <hr style={{ borderTop: "dotted 1px;" }} />
      <div className="row">
        <div className="col-xs-12 table">
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: "59%" }}>Description</th>
                <th>Qty</th>
                <th align="right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoiceItems.map((invoice_item) => (
                <tr key={invoice_item.id}>
                  <td>{invoice_item.description}</td>
                  <td>{invoice_item.quantity}</td>
                  <td align="right">{invoice_item.line_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TRAInvoiceItemsView;
